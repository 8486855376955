<template>
  <div class="mb-100">
    <div class="divider-full"></div>
    <div class="cotainer-standar">
      <div class="container-standar mb-10 pad-20">
        <!-- <h4 class="font-weight-7 text-primary font-20 bor-bot-1 mb-30 pb-4">Profil Desa Hanjuang</h4> -->
        <div class="text-center" v-if="loading">
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>
        <div class="row" v-else>
          <div class="col-lg-4">
            <div class="card shadow border-radius-10 pad-10 detail-potensi">
              <!-- <b-img :src="potensiKampung.photo_kepala_kampung"> </b-img> -->
              <h3 class="font-24 text-primary font-weight-7 mt-2 mb-20">
                Detail Potensi type
              </h3>
              <div class="cont-img">
                <b-img src="https://pbs.twimg.com/media/DHlpbuPUwAANT69.jpg" alt fill />
              </div>
              <h3 class="font-22 font-weight-7 mt-10 mb-5 text-primary">
                Nama Kampung
              </h3>
              <div class="list-static">
                <span
                  class="
                    d-inline-block
                    width-100
                    font-18 font-weight-6
                    text-grey
                  "
                >
                  Luas Wilayah
                  <span class="float-right text-primary font-weight-7"
                    >- Km</span
                  >
                </span>
              </div>
              <div class="list-static">
                <span
                  class="
                    d-inline-block
                    width-100
                    font-18 font-weight-6
                    text-grey
                  "
                >
                  Jumlah Penduduk
                  <span class="float-right text-primary font-weight-7"
                    >- Jiwa</span
                  >
                </span>
              </div>
              <div class="list-static">
                <span
                  class="
                    d-inline-block
                    width-100
                    font-18 font-weight-6
                    text-grey
                  "
                >
                  Kepadatan Penduduk
                  <span class="float-right text-primary font-weight-7"
                    >- KK/Km</span
                  >
                </span>
              </div>
              <h3 class="font-24 text-primary font-weight-7 mt-2 mb-10">
                Informasi
              </h3>
              <div class="list-static">
                <span
                  class="
                    d-inline-block
                    width-100
                    font-18 font-weight-7
                    text-primary
                  "
                  >Kepala Type:
                  Nama Kepala </span
                >
                <span class="text-grey">
                  Alamat: Alamat,
                   <br />
                  Telp: 021-2345234/e-mail: admin@mail.com
                </span>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="card shadow border-radius-10">
              <b-tabs content-class="mt-3 pad-10">
                <b-tab title="Peta" active>
                  <iframe
                    src="https://www.google.com/maps/d/embed?mid=13HHjRpGvZx1PZ1Jipi8rvuv2BXc&hl=en"
                    width="100%"
                    height="480"
                  ></iframe>
                </b-tab>
                <b-tab title="Profile">
                  <div class="row pad-20">
                    <div class="col-md-6 col-xs-12 mb-40">
                      <h3 class="text-primary font-20">
                        <strong>Visi Desa</strong>
                      </h3>
                      <p class="font-weight-6 text-grey mt-2">
                        Visi
                      </p>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-40">
                      <h3 class="text-primary font-20">
                        <strong>Misi Desa</strong>
                      </h3>
                      <p class="font-weight-6 text-grey mt-2">
                        Misi
                      </p>
                    </div>
                    <div class="col-md-4 col-xs-6">
                      <span
                        class="
                          font-weight-6
                          text-grey
                          width-100
                          d-inline-block
                          pb-6
                        "
                        >Nama Kampung/Desa</span
                      >
                      <span
                        class="
                          font-weight-6
                          text-grey
                          width-100
                          d-inline-block
                          pb-6
                        "
                        >Kode Kampung/Desa</span
                      >
                      <span
                        class="
                          font-weight-6
                          text-grey
                          width-100
                          d-inline-block
                          pb-6
                        "
                        >Tahun Pembentukan</span
                      >
                      <span
                        class="
                          font-weight-6
                          text-grey
                          width-100
                          d-inline-block
                          pb-6
                        "
                        >Dasar Hukum Pembentukan</span
                      >
                      <span
                        class="
                          font-weight-6
                          text-grey
                          width-100
                          d-inline-block
                          pb-6
                        "
                        >Provinsi</span
                      >
                      <span
                        class="
                          font-weight-6
                          text-grey
                          width-100
                          d-inline-block
                          pb-6
                        "
                        >Kabupaten/Kota</span
                      >
                      <span
                        class="
                          font-weight-6
                          text-grey
                          width-100
                          d-inline-block
                          pb-6
                        "
                        >Distrik</span
                      >
                      <span
                        class="
                          font-weight-6
                          text-grey
                          width-100
                          d-inline-block
                          pb-6
                        "
                        >Kode Pos</span
                      >
                    </div>
                    <div class="col-md-8 col-xs-6">
                      <span
                        class="
                          font-weight-7
                          text-primary
                          width-100
                          d-inline-block
                          pb-6
                        "
                        >: Nama Kampung/Desa
                        </span
                      >
                      <span
                        class="
                          font-weight-7
                          text-primary
                          width-100
                          d-inline-block
                          pb-6
                        "
                        >: Kode</span
                      >
                      <span
                        class="
                          font-weight-7
                          text-primary
                          width-100
                          d-inline-block
                          pb-6
                        "
                        >: 2018</span
                      >
                      <span
                        class="
                          font-weight-7
                          text-primary
                          width-100
                          d-inline-block
                          pb-6
                        "
                        >: PUBG-205-100</span
                      >
                      <span
                        class="
                          font-weight-7
                          text-primary
                          width-100
                          d-inline-block
                          pb-6
                        "
                        >: PAPUA</span
                      >
                      <span
                        class="
                          font-weight-7
                          text-primary
                          width-100
                          d-inline-block
                          pb-6
                        "
                        >: Nama </span
                      >
                      <span
                        class="
                          font-weight-7
                          text-primary
                          width-100
                          d-inline-block
                          pb-6
                        "
                        >: Nama Distrik</span
                      >
                      <span
                        class="
                          font-weight-7
                          text-primary
                          width-100
                          d-inline-block
                          pb-6
                        "
                        >: -</span
                      >
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Data Umum">
                  <div class="row">
                    <div class="col-md-6 col-xs-12 pr-0">
                      <div class="pad-10 pr-0">
                        <legend class="font-weight-7 font-18 text-primary">
                          Info Wilayah
                        </legend>
                        <table class="table table-striped">
                          <tbody>
                            <tr>
                              <th>Tipologi</th>
                              <td>: -</td>
                            </tr>
                            <tr>
                              <th>Ketinggian</th>
                              <td>: 1 (MDPL)</td>
                            </tr>

                            <tr>
                              <th>Luas Wilayah</th>
                              <td>
                                : -
                                <span>
                                  km
                                  <sup>2</sup>
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <th>Dasar Hukum Pembentukan</th>
                              <td>: PEREGUB No 4 1990</td>
                            </tr>

                            <tr>
                              <th>Kode Pos</th>
                              <td>: -</td>
                            </tr>

                            <tr>
                              <th>Kabupaten/Kota</th>
                              <td>: -</td>
                            </tr>

                            <tr>
                              <th>Jumlah Penduduk</th>
                              <td>: -</td>
                            </tr>

                            <tr>
                              <th>Laki Laki</th>
                              <td>: -</td>
                            </tr>

                            <tr>
                              <th>Perempuan</th>
                              <td>: -</td>
                            </tr>
                          </tbody>
                        </table>

                        <legend class="font-weight-7 font-18 text-primary">
                          Batas Wilayah
                        </legend>
                        <table class="table table-striped">
                          <tbody>
                            <tr>
                              <th>Utara</th>
                              <td>
                                : -
                              </td>
                            </tr>

                            <tr>
                              <th>Timur</th>
                              <td>
                                : -
                              </td>
                            </tr>

                            <tr>
                              <th>Selatan</th>
                              <td>
                                : -
                              </td>
                            </tr>

                            <tr>
                              <th>Barat</th>
                              <td>
                                : -
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-md-6 col-xs-12 pl-0">
                      <div class="pad-10 pl-0">
                        <legend class="font-weight-7 font-18 text-primary">
                          A. Sarana Kesehatan
                        </legend>
                        <table class="table table-striped">
                          <tbody>
                            <tr>
                              <th>1. Puskesmas</th>
                              <td>: -</td>
                            </tr>

                            <tr>
                              <th>2. Posyandu</th>
                              <td>: -</td>
                            </tr>

                            <tr>
                              <th>3. Pondok Bersalin</th>
                              <td>: -</td>
                            </tr>
                          </tbody>
                        </table>

                        <legend class="font-weight-7 font-18 text-primary">
                          B. Sarana Pendidikan
                        </legend>
                        <table class="table table-striped">
                          <tbody>
                            <tr>
                              <th>1. PAUD/Sederajat</th>
                              <td>: -</td>
                            </tr>

                            <tr>
                              <th>2. SD/Sederajat</th>
                              <td>: -</td>
                            </tr>

                            <tr>
                              <th>3. SMP/Sederajat</th>
                              <td>: -</td>
                            </tr>

                            <tr>
                              <th>4. SMA/Sederajat</th>
                              <td>: -</td>
                            </tr>
                          </tbody>
                        </table>
                        <legend class="font-weight-7 font-18 text-primary">
                          C. Sarana Umum
                        </legend>
                        <table class="table table-striped">
                          <tbody>
                            <tr>
                              <th>1. Masjid Besar</th>
                              <td>: -</td>
                            </tr>

                            <tr>
                              <th>2. Gereja</th>
                              <td>: -</td>
                            </tr>

                            <tr>
                              <th>3. Pasar</th>
                              <td>: -</td>
                            </tr>

                            <tr>
                              <th>4. Balai Pertemuan</th>
                              <td>
                                : -
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const potensiKampung = createNamespacedHelpers("potensiKampung");

export default {
  data() {
    return {};
  },
  computed: {
    ...potensiKampung.mapState(["potensiKampung", "loading"]),
  },
  created() {
    // this.getPotensiKampungById(this.$route.params.id);
  },
  mounted() {
    this.$store.commit("SET_BG", false);
  },
  methods: {
    ...potensiKampung.mapActions(["getPotensiKampungById"]),
  },
};
</script>
